.projectUser {
  min-width: 6rem !important;
}
.custom .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #5c6be8;
  border-color: #5c6be8;
}
.custom .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5c6be8;
}
